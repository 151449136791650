import React from "react";
import { render } from "react-dom";

import { initStore } from "./states";

(async function () {
  const { store } = await initStore();
  const appModule = await import("./components/App");
  const App = appModule.default;

  render(<App store={store} />, document.getElementById("root"));
})();
