import { ActionType, getType } from "typesafe-actions";
import { actions } from "./actions";
import { AppList } from "@eai-toolkit/client";
import { DEFAULT_LIMIT } from "../../helpers/pagination";

export type AppConfigsState = {
  params: {
    limit: number;
    offset: number;
  };
  data: AppList | null;
};

const INITIAL_STATE: AppConfigsState = {
  data: null,
  params: { limit: DEFAULT_LIMIT, offset: 0 },
};

export function apps(
  state: AppConfigsState = INITIAL_STATE,
  action: ActionType<typeof actions>,
): AppConfigsState {
  switch (action.type) {
    case getType(actions.getList.success): {
      return { ...state, data: action.payload };
    }
    default:
      return state;
  }
}
