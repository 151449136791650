export function assertIsError(error: unknown): asserts error is Error {
  if (!(error instanceof Error)) {
    throw error;
  }
}

function getScriptBySource(source: string): Element | null {
  return document.querySelector(`script[src='${source}']`);
}

function isScriptLoaded(source: string): Element | boolean {
  return getScriptBySource(source) || false;
}

export async function loadScript(
  source: string,
  force = false,
): Promise<Event | void> {
  if (isScriptLoaded(source) && !force) {
    return;
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = source;
    script.onload = resolve;
    const loadingErrorHandler = (err: Error) => {
      setTimeout(script.remove.bind(script), 0);
      reject(err.message);
    };
    script.onerror = loadingErrorHandler.bind(
      null,
      new Error(`Failed to load script from ${source}`),
    );

    const firstScript = document.getElementsByTagName("script")[0];
    try {
      firstScript.parentNode!.insertBefore(script, firstScript);
    } catch (err: unknown) {
      assertIsError(err);
      console.error(err);
      loadingErrorHandler(err);
    }
  });
}

export function removeScript(source: string): void {
  const script = isScriptLoaded(source);
  if (script) {
    (script as Element).remove();
  }
}

export function matchDashboard(pathname: string) {
  return (location: Location) =>
    location.pathname === "/" || location.pathname.startsWith(pathname);
}

export function matchingPathname(pathnames: Array<string>) {
  return (location: Location) => {
    return pathnames.some((pathname: string) =>
      location.pathname.startsWith(pathname),
    );
  };
}

export const waitUntil = (test: () => boolean) =>
  new Promise((resolve) => {
    const step = () => {
      if (test()) {
        resolve(null);
        return;
      }
      window.requestAnimationFrame(step);
    };
    window.requestAnimationFrame(step);
  });
