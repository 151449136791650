import { ActionType, getType } from "typesafe-actions";
import { actions } from "./actions";
import { SnackbarMessage } from "../../components/Snackbar";

export type SnackbarState = Array<SnackbarMessage>;

const INITIAL_STATE: SnackbarState = [];

export function snackbar(
  state: SnackbarState = INITIAL_STATE,
  action: ActionType<typeof actions>,
): SnackbarState {
  // api errors
  if (action.type.startsWith("api/") && action.type.endsWith("_FAILURE")) {
    return [
      ...state,
      { variant: "error", message: "An unknown error occurred." },
    ];
  }
  switch (action.type) {
    case getType(actions.addSnackbarQueueItem): {
      return [...state, action.payload];
    }
    case getType(actions.snackbarQueueItemProcessed): {
      return state.slice(1);
    }
    default:
      return state;
  }
}
