import { AppApi, AppList } from "@eai-toolkit/client";
import { ThunkActionCreator } from "..";
import { createAsyncAction } from "typesafe-actions";
import { apiConfiguration } from "../../helpers/api";
import { assertIsError } from "../../AppManager/utils";

const appApi = new AppApi(apiConfiguration);

export const actions = {
  getList: createAsyncAction(
    "api/GET_APPS_REQUEST",
    "api/GET_APPS_SUCCESS",
    "api/GET_APPS_FAILURE",
  )<void, AppList, Error>(),
};

type ListParams = {
  limit: number;
  offset: number;
};
export const getList: ThunkActionCreator<ListParams> = (
  params: ListParams,
) => async (dispatch) => {
  dispatch(actions.getList.request());
  const { limit, offset } = params;

  try {
    const res = await appApi.v1MeAppGet({ offset, limit });
    dispatch(actions.getList.success(res.data));
  } catch (err) {
    assertIsError(err);
    console.error(err);
    dispatch(actions.getList.failure(err));
  }
};
