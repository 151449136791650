import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
  StoreEnhancer,
} from "redux";
import thunk, { ThunkAction } from "redux-thunk";
import { History, createBrowserHistory } from "history";
import { snackbar as snackbarReducer, SnackbarState } from "./snackbar/reducer";
import { auth as authReducer, AuthState } from "./auth/reducer";
import { apps as appsReducer, AppConfigsState } from "./apps/reducer";
import { AuthStorage } from "../local-storage";
import Config from "../config";

declare global {
  interface Window {
    env: { [key: string]: string };
    __REDUX_DEVTOOLS_EXTENSION__: any;
  }
}

// add actions here...
export type Actions = any;

type ThunkResult<R> = ThunkAction<R, StoreState, undefined, Actions>;

export type ThunkActionCreator<P = void, R = void> = (
  params: P,
) => ThunkResult<R>;

export interface StoreState {
  snackbar: SnackbarState;
  auth: AuthState;
  apps: AppConfigsState;
}

interface InitStore {
  store: Store<StoreState>;
  history: History;
}

const publicURL = process.env.PUBLIC_URL;

const authStorage = new AuthStorage();

export async function initStore(): Promise<InitStore> {
  const storeInitObj: any = {};

  const history = createBrowserHistory({
    basename: publicURL,
  });

  const enhancers: Array<StoreEnhancer> = [];
  const middlewares = [thunk.withExtraArgument({})];

  if (Config.isDev) {
    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
      enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    if (!authStorage.isExpired()) {
      storeInitObj.auth = authStorage.get()!.session;
    } else {
      authStorage.clear();
    }
  }

  const store = createStore(
    combineReducers({
      snackbar: snackbarReducer,
      auth: authReducer,
      apps: appsReducer,
    }),
    storeInitObj,
    // @ts-ignore
    compose(applyMiddleware(...middlewares), ...enhancers),
  );

  return {
    store,
    history,
  };
}
