import { createAsyncAction } from "typesafe-actions";
import { ThunkActionCreator } from "..";
import {
  MeApi,
  User,
  LoginApi,
  LoginApiAxiosParamCreator,
  LoginProvidersInner,
  LoginApiOidcLoginRequest,
} from "@eai-toolkit/client";
import { assertIsError } from "../../AppManager/utils";

import { apiConfiguration } from "../../helpers/api";
import Config from "../../config";
import { AxiosError } from "axios";

export const actions = {
  loggedInUser: createAsyncAction(
    "login/LOGIN_REQUEST",
    "api/ME_SUCCESS",
    "api/ME_FAILURE",
  )<void, User, Error>(),
  getTerms: createAsyncAction(
    "api/GET_TERMS_REQUEST",
    "api/GET_TERMS_SUCCESS",
    "api/GET_TERMS_FAILURE",
  )<void, string, Error>(),
  agreeToTerms: createAsyncAction(
    "api/SET_TERMS_REQUEST",
    "api/SET_TERMS_SUCCESS",
    "api/SET_TERMS_FAILURE",
  )<void, string, Error>(),
  oidcLogin: createAsyncAction(
    "login/oidc/auth/OIDC_REQUEST",
    "login/oidc/auth/OIDC_SUCCESS",
    "login/oidc/auth/OIDC_FAILURE",
  )<void, string, AxiosError>(),
  getProviders: createAsyncAction(
    "providers/GET_REQUEST",
    "providers/GET_SUCCESS",
    "providers/GET_FAILURE",
  )<void, LoginProvidersInner[], AxiosError>(),
};

const meApi = new MeApi(apiConfiguration);
const loginApi = new LoginApi(apiConfiguration);
const loginApiParamsCreator = LoginApiAxiosParamCreator(apiConfiguration);

export const oidcLogin: ThunkActionCreator<LoginApiOidcLoginRequest> = (
  oidcLoginRequest,
) => async (dispatch) => {
  dispatch(actions.oidcLogin.request());
  try {
    const params = await loginApiParamsCreator.oidcLogin(oidcLoginRequest.providerId, oidcLoginRequest.loginChallenge);
    // Redirect the user to the new URL
    window.location.href = params.url;
  } catch (err) {
    dispatch(actions.oidcLogin.failure(err as AxiosError));
  }
};

export const getProvidersList: ThunkActionCreator = () => async (dispatch) => {
  dispatch(actions.getProviders.request());

  try {
    const { data } = await loginApi.getProviders();
    dispatch(actions.getProviders.success(data));
  } catch (err) {
    assertIsError(err);
    console.error(err);
    dispatch(actions.getProviders.failure(err as AxiosError));
  }
};

export const loggedInUser: ThunkActionCreator = () => async (dispatch) => {
  dispatch(actions.loggedInUser.request());

  try {
    const { data } = await meApi.v1MeGet({});
    dispatch(actions.loggedInUser.success(data as User));
  } catch (err) {
    if (!Config.isDev) {
      // this is extremely annoying during local development so let's skip it..
      // no session cookie? redirect to console.elementai.com/login
      window.location.href = "/login";
    }
  }
};

export const getTerms: ThunkActionCreator = () => async (dispatch) => {
  dispatch(actions.getTerms.request());

  try {
    const { data } = await meApi.v1MeTermsGet();
    dispatch(actions.getTerms.success(data));
  } catch (err) {
    assertIsError(err);
    console.error(err);
    dispatch(actions.getTerms.failure(err));
  }
};

export type AgreeToTermsType = (params: void) => Promise<void>;

export const agreeToTerms: ThunkActionCreator<
  void,
  Promise<void>
> = () => async (dispatch) => {
  dispatch(actions.agreeToTerms.request());

  try {
    const { data } = await meApi.v1MeTermsPut();
    dispatch(actions.agreeToTerms.success(data));
  } catch (err) {
    assertIsError(err);
    console.error(err);
    assertIsError(err);
    dispatch(actions.agreeToTerms.failure(err));
  }
};
